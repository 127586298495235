<template>
  <div class="wx-login-hint">
    <div v-if="type === 'oa'" class="oa-login-hint">
      <p>
        {{ requesting ? "登录中..." : logined ? "登录成功" : "登录失败" }}
      </p>
    </div>
    <div v-else-if="type === 'op'" class="op-login-hint">
      <p>
        {{ requesting ? "登录中..." : logined ? "登录成功" : "登录失败" }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "wx-redirect",
  data() {
    return {
      code: this.$route.query.code,
      requesting: false,
      logined: false,
      type: this.$route.query.type
    };
  },
  mounted() {
    const that = this;
    if (that.code) {
      that.requesting = true;
      switch (that.type) {
        case "oa":
          that.wxLogin("officialAccount");
          break;
        case "op":
          that.wxLogin("openPlatform");
          break;
        default:
          that.requesting = false;
          break;
      }
    }
    that.$nextTick(() => {
      if (that.type === "op") {
        document.querySelector(".page-header-container").style.cssText =
          "display:none;";
        document.querySelector(".page-footer-container").style.cssText =
          "display:none;";
      }
    });
  },
  methods: {
    ...mapActions("user", ["login"]),
    wxLogin(type) {
      const that = this;
      that
        .login({
          type: type,
          code: that.code
        })
        .then(res => {
          that.logined = true;
          const data = res.data;
          that.afterLogin(data);
          that.$router.push({
            path: "/"
          });
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          that.requesting = false;
        });
    },
    oaLogin() {
      const that = this;
      that
        .login({
          type: "wechat",
          code: that.code
        })
        .then(res => {
          that.logined = true;
          const data = res.data;
          that.afterLogin(data);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          that.requesting = false;
        });
    },
    opLogin() {
      const that = this;
      that.requesting = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.wx-login-hint {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
}
.op-login-hint {
  width: 100%;
  height: 100%;
}
.oa-login-hint {
  p {
    text-align: center;
    font-size: 14px;
    white-space: nowrap;
  }
}
</style>
